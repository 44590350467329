.imghere,.data-analysis-imghere,.public-sector-cyber-security-imghere,.software-development-system-integration-imghere {
    width: 100%;
    aspect-ratio: 16/6;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.imghere{
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent), url(../../public/images/consulting.jpeg);
}
.data-analysis-imghere{
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent), url(../../public/images/data-analysis-emerging.jpg);
}
.public-sector-cyber-security-imghere{
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent), url(../../public/images/public-sector-cyberSecurity.jpg);
}
.software-development-system-integration-imghere{
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent), url(../../public/images/software-development-sys-integration.jpg);
}
.homeservices li {
    font-size: 0.9rem;
    margin: auto 0;
    margin: 0 0.8rem;
}

.homeservices li:hover {
    text-decoration: underline;
    cursor: pointer;
}

.homeservices li::before {
    content: '«';
    font-size: 17px;
    font-weight: 600;
    margin: 0 5px;
}

.HowGCT li,.HowGCT-White li {
    font-size: 1.2rem;
    margin: 0.9rem 0;
}
.HowGCT li{
    color: black;
}
.HowGCT-White li {
    color: white;
}

.HowGCT li::before, .HowGCT-White li::before {
    content: '▶';
    margin-right: 10px;
}
.HowGCT li::before {
    color: rgb(14, 23, 52);
}
.HowGCT-White li::before {
    color:white;
}

@media only screen and (max-width:768px) {
    .imghere {
        aspect-ratio: 1/1 !important;
    }
}