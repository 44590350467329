#carouselId .carousel-indicators li,
#carouselId .carousel-indicators li {
    width: 12px;
    height: 12px;
    background-color:var(--bs-primary) !important;
    border: 1px solid #FCEEC6 !important;
    opacity: 1;
    border-radius: 12px;
    margin: 0 10px 30px 10px;
}

#carouselId .carousel-indicators li.active {
    background: var(--bs-info) !important;
}
