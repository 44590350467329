span.fl-icon{
    display: block;  
    text-align: center; 
    font-size: 70px;
}
.row .col:nth-child(odd) span.fl-icon i {
    color: var(--bs-primary) !important;
}
.row .col:nth-child(even) span.fl-icon i {
    color: var(--bs-info) !important;
}

span.fl-heading-text{
   font-size: large; 
   color:var(--bs-dark);
}
/* //////////////// Progress bar star //// */
.row-content-wrap {
    background-image: linear-gradient(90deg, var(--bs-primary) 0%, var(--bs-info) 100%) !important;
}
/* /////////////// */
.row-content-wrap-2 {
    background-image: radial-gradient(at left bottom, var(--bs-info) 0%, #0e274d 50%);
}
/* //////////////////// */
.progress-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.progress-circle svg {
    position: absolute;
    top: 0;
    left: 0;
}

.progress-circle .fl-bar-bg {
    stroke: var(--bs-secondary); /* Background color */
    stroke-width: 20;
}

.progress-circle .fl-bar {
     stroke: var(--bs-light); /* Progress color */
    stroke-width: 20; /* Adjust the stroke width as needed */
    stroke-dasharray: 0;
    transition: stroke-dasharray 2s ease-in-out;
}
.fl-number-text {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-size: 3rem;
font-weight: bold;
}
/* //////////Progress bar end ///// */