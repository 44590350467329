
.content{
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    
}
.cards1{
    width:250px;
    display: flex;
}
.cards1 i{
    padding:10px;
    margin:10px;
    font-size: 25px;
    border:1px solid black;
    border-radius: 50%;
    background-color: rgb(50, 50, 207);
    color:white;
    border: none;
}