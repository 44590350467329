#intro .custom-block h3.entry-title {
    min-height: auto
}

#intro h3.av-special-heading-tag {
    font-size: 14px !important;
    font-weight: 800
}

#intro .avia_image {
    margin-bottom: 10px !important
}
