#shape {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}



#shape::before {
    content: '';
    top: -100%;
    left: 42.5%;
    transform: translate(-50%, -50%);
    width: 200px !important;
    height: 200px !important;
    background: rgb(231, 59, 61);
    border-radius: 25px;
    position: absolute;
    transform: rotate(45deg);
    z-index: -1;
}

#shape::after {
    content: '';
    top: -80%;
    left: 43.5%;
    position: absolute;
    width: 170px;
    height: 170px;
    transform: rotate(45deg);
    border: 2px rgb(255, 255, 255) solid;
    border-radius: 25px;
}
@media only screen and (max-width:1000px) {
    #shape::before {
        left: 41.5%;
    }
}
@media only screen and (max-width:992px) {
    #shape::before {
        left: 35.5%;
    }

    #shape::after {
        left: 37.5%;
    }
}


#security li::before {
    content: '✅ ';
}

#security li {
    margin-top: 1rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
}