a {
    text-decoration: none;
    color: white;
}


.logo img {
    color: white;
}

.nav {
    display: flex;
    justify-content: center;
    /* position: relative; */
}

.nav li {
    padding: 14px;
    list-style: none;
}


.sub-nav {
    display: none;
    position: absolute;
    background-color: beige;
    padding: 10px 0px !important;
    top: 100%;
    left: 55%;
    text-align: start;
    font-size: 14px;
    ;

}

.sub-nav li {
    padding: 20px;
}

.sub-nav a {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center
}

.sub-nav a:hover {
    color: red;

}

.servicess:hover .sub-nav {
    display: block;
    transition: all ease 0.3s;
}

.sub-nav1 {
    display: none;
    position: absolute;
    /* border:1px solid black; */
    /* border-radius: 10px; */
    background-color: beige;
    color: black;
    width: 170px;
    top: 100%;
    left: 66%;
}

.sub-nav1 a {
    color: black;
}

.servicess:hover .sub-nav1 {
    display: block;
    transition: all ease 0.3s;
    padding: 10px;
    color: red;
}

.logo img {
    color: white;
}

.nav {
    display: flex;
    justify-content: center;
}

.nav li {
    padding: 20px;
    list-style: none;
}

.menu {
    display: none;
}

.cut {
    font-size: 30px;
    margin-top: 20px;
    ;
    display: none;
}

/* @media screen and (max-width:600px){
      .menu{
        display: block;
        font-size: 28px;;
        margin-top: 25px;;

      }
      .nav li{
        display: none;
      }
      .addnav {
           display: flex;
           flex-direction: column;
           align-items: start;

      }
      .addnav li {
           display: block;
           padding: 10px;
           margin-top: 50px;
      }
} */
.banner-img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.about-design {
    width: 1000px;
    margin: auto;
}

.about-design span {
    color:var(--bs-info) !important;
    margin-bottom: 20px;
}

.content {
    margin-top: 40px;
    ;
}

.lines {
    display: flex;
    padding: 10px;
}

.lines .line {
    margin-left: 10px;
    font-size: 1.25rem !important;
}

.ui-ux {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ui-ux img {

    width: 700px;
}

.number1 {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    background-color: rgb(255, 255, 255);
}

.imges img {
    width: 400px;
    padding: 20px
}

.faculty {
    width: 500px;
    padding: 20px
}

.foter {
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    /* background-image: url('./Img/map.jpg'); */
    background-repeat: no-repeat;
    color: white;
    padding: 20px;
}

.foter ul>li {
    margin: 15px;
    font-size: 15px;
}

@media screen and (max-width:600px) {
    .foter {
        flex-direction: column;
    }

    iframe {
        width: 80%;
    }

    .copyright {
        width: 100%;
    }

    .about-design {
        width: 280px;
    }

    .ui-ux img {
        width: 280px;
    }

    .number1 {
        flex-direction: column;

    }

    .imges img {
        width: 280px;

    }

    .faculty {
        width: 300px;
        margin: auto;
        /* text-align: center; */
    }
}