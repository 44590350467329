.Industries {    
    /* background-image: url('./white_polygon.jpg'); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
}
span i{
    font-size: 12px;;
    margin-left: 5px;
}



