/* widget-nav-menu */
.widget.widget-nav-menu ul{
    padding-left: 0px !important;
}
.widget.widget-nav-menu ul li{ 
    margin-bottom: 2px;
    padding: 0;
}
.widget.widget-nav-menu ul li a{
    display: block;
    padding: 14px 30px 14px 24px;
    position: relative;
    background-color: #0dcaf0;
    z-index: 1;
    font-weight: 500;
    font-size: 15px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.widget.widget-nav-menu ul li{
    list-style: none;
    border-top: 1px solid white;
    margin:0px;
}
.widget.widget-nav-menu ul li a:hover{
    background-color: var(--bs-primary) !important;
    color:white  !important;
}
.widget-menu .active a{
    background-color: var(--bs-primary) !important;
    color:white !important;
}


/* Paramedical Staffing beging */
.toggle-title{
    background-color: var(--bs-info) !important;
}

.toggle-title:hover h3{
    background-color: var(--bs-primary) !important;
    color: white !important;
}
.accordian .card{
    width: 100% !important;
    height: auto !important;
}
.toggle-content{
    padding: 10px !important;
}
.widget-menu .nav-item{
    background-color: var(--bs-primary) !important;
    color:white !important;
    margin: 1rem !important;
}