.recrutment-center{
    display:flex;
    justify-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width:1200px;
    

}
.recrutment-center li{
       background-color: rgb(5, 5, 87);
       padding:10px;
       margin:15px;
       border-radius: 10px;;
       color:white;
}