.imghere {
    width: 100%;
    aspect-ratio: 16/6;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent), url(../../public/images/consulting.jpeg);
}

.homeservices li {
    font-size: 0.9rem;
    margin: auto 0;
    margin: 0 0.8rem;
}

.homeservices li:hover {
    text-decoration: underline;
    cursor: pointer;
}

.homeservices li::before {
    content: '«';
    font-size: 17px;
    font-weight: 600;
    margin: 0 5px;
}

.HowBDO li {
    font-size: 1.2rem;
    margin: 0.9rem 0;
    color: black;
}

.HowBDO li::before {
    content: '▶';
    color: rgb(14, 23, 52);
    margin-right: 10px;
}

@media only screen and (max-width:768px) {
    .imghere {
        aspect-ratio: 1/1 !important;
    }
}