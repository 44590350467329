/* img {
    position: relative;
    text-align: left;
} */

.text {
    position: absolute;
    top: 230px;
    color: white;
    left: 80px;
    font-family: Arial, Helvetica, sans-serif;
}

.font {
    position: absolute;
    top: 280px;
    color: white;
    left: 80px;
    font-style: inherit
}

.down {
    position: absolute;
    top: 300px;
    color: white;
    left: 80px;
    font-style: inherit
}

.heading {
    background-color: blue;
    margin-top: 1px;
    border-radius: 10px;
}

.heading:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 0px solid transparent;
    border-right: 300px solid transparent;
    border-bottom: 20px solid #fff;
}

.card{
    overflow: hidden;
}

@media only  screen and (min-width:1130px){
    .card{
        font-size: 15px!important;
    }  
}

@media only  screen and (min-width:900px){
    .card{
        font-size: 14px;
    }  
}
@media only  screen and (max-width:800px){
    .card{
        font-size:16px!important;
    }  
}
.btn1{
    position: fixed;
    z-index: 1;
    padding:6px;
    border-radius: 10px;
    /* box-shadow: 1px 1px 1px solid rgb(246, 166, 18); */
    border:1px solid rgb(255, 174, 0);
    font-size: 18px;
    width:150px;
    height:40px;
    text-align: center;
    top:90%
}